//
// Copied from https://github.com/alexreardon/tiny-invariant/blob/master/src/tiny-invariant.ts
// Modified to keep the message in production and log them. This increase the
// build size but makes it much easier to debug invariant violations in
// production!
//

const isProduction: boolean = process.env.NODE_ENV === 'production'
const prefix: string = 'Invariant failed'

export default function invariant(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: any,
  message?: string | (() => string)
): asserts condition {
  if (condition) {
    return
  }
  // Throw an error if the condition fails
  // Condition not passed

  const provided: string | undefined =
    typeof message === 'function' ? message() : message

  // fullMessage is either:
  // 1. message provided: `${prefix} (${provided})`
  // 2. message not provided: prefix
  const fullMessage: string = provided ? `${prefix} (${provided})` : prefix

  // in production, throw the error with only the prefix as message to avoid
  // leaking any information but log an error with the full message
  if (isProduction) {
    // eslint-disable-next-line no-console
    console.error(new Error(fullMessage))

    throw new Error(prefix)
  }

  // in other envs, throw the full message
  throw new Error(fullMessage)
}
